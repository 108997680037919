import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../Gracias.css'; // Asegúrate de crear y vincular este archivo CSS

const Gracias = () => {
  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {'send_to': 'AW-16643628397/P_k4CNaw0MMZEO26poA-'});
    }
  }, []);

  return (
    <div className="gracias-background">
      <Container className="h-100 d-flex align-items-center justify-content-center">
        <Row className="text-center">
          <Col>
            <h1 className="gracias-title">COTIZACIÓN RECIBIDA</h1>
            <p className="gracias-subtitle my-3"><a href="tel:18704556742" style={{ color: '#8FB93E', textDecoration: 'none' }} className='font-jura'>¿Tienes dudas? Llámanos al (1) 870-455-6742</a></p>
            <Button href="/" variant="success" className="gracias-button">Volver a inicio</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Gracias;
