import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import '../Cards.css'; // Asegúrate de crear y vincular este archivo CSS

const Cards = () => {

  return (
    <Container className="py-5 px-3" id='quienes'>
      <Row>
        <Col md={4}>
          <Card className="custom-card px-3">
            <Card.Img variant="top" src="images/card1.png" className=''/>
            <Card.Body className=''>
              <div className="card-icon">
                <img src="images/iconcard1.svg" alt="icon1" />
              </div>
              <Card.Title>Transportamos tu vehículo En USA</Card.Title>
              <Card.Text>
                Transporte de carros asegurado puerta a puerta. Todo tipo de Vehículos: Carros, RVs, Motocicletas, Camiones, Camionetas, Autos Clásicos, ATVs, Botes, Lanchas y mucho más. Somos el servicio preferido de los HISPANOS! Llamanos, chateanos o envíanos la información para hacerte un estimado final.
              </Card.Text>
              <a className="custom-button my-4 text-decoration-none" href='#home'>Estimado Gratis →</a>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="custom-card px-3">
            <Card.Img variant="top" src="images/card2.png" />
            <Card.Body>
              <div className="card-icon">
                <img src="images/iconcard2.svg" alt="icon2" />
              </div>
              <Card.Title>Servicio personalizado en español</Card.Title>
              <Card.Text>
                Siempre alguien te atenderá personalmente desde el comienzo hasta el final del proceso. Obtendrás información rápida y gratis para el transporte de tu vehículo dentro y fuera de los Estados Unidos. Sin cargos ni costos adicionales, la tarifa que ofrecemos es el precio final, garantizado.
              </Card.Text>
              <a className="custom-button my-4 text-decoration-none" href='mailto:info@tucarroporusa.com'>Déjanos atenderte →</a>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="custom-card px-3">
            <Card.Img variant="top" src="images/card3.png" />
            <Card.Body>
              <div className="card-icon">
                <img src="images/iconcard3.svg" alt="icon3" />
              </div>
              <Card.Title>Reserva tu transporte</Card.Title>
              <Card.Text >
                Una vez apruebes tu cotización con tu agente podrás reservar tu espacio, lo puedes hacer telefónicamente con tu agente. Puedes reservar con tarjetas de crédito o débito. Una vez recibas tu carro pagarás el excedente al conductor (COD) en efectivo o money order.
              </Card.Text>
              <a className="custom-button mt-4 py-2 text-decoration-none" href='tel:18704556742'>Reserva con nosotros →</a>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Cards;
